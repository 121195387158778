import React from 'react'
import { IconElement } from 'shared/ui/kit'

const NewSlideIcon = () => (
  <IconElement
    name="newSlideSmall"
    style={{
      border: '5px solid var(--color-bg-2)',
      borderRadius: '50%',
      color: 'var(--color-blue)',
      position: 'absolute',
      right: -7,
      top: -6,
      zIndex: 2,
    }}
  />
)

export default NewSlideIcon
