import { CaseFormDataType } from 'features/cases/ui/CaseForm'
import { notices } from 'features/notices'
import { selectAtlasViewerUrlSlideId, selectTasksViewerUrlTaskId } from 'pages/viewer//model/viewerPageSlice'
import { useTranslation } from 'react-i18next'
import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { useSelector } from 'react-redux'
import { del, post, QUERY_TYPE } from 'shared/api'
import { IListOfItemsCursorCases } from 'types/api/IListOfItemsCursor'
import ICase, { ICaseDocument, ICaseDTO } from 'types/ICase'
import ISlide from 'types/ISlide'
import { ISlideGrouped } from 'types/ISlideGrouped'
import ISource from 'types/ISource'
import { convertToISlide } from 'viewer/map/layers/slide/helpers/converter'

import caseService, { CaseBiomaterial, CaseReference, IFile } from './service'

export type CasesParams = {
  relation: 'owner' | 'shared'
  status: 'open' | 'archive'
  searchQuery?: string
  workspaceId?: string
}

export type PaginatedCasesParams = {
  caseId?: string | null
} & CasesParams

export const useCasesQuery = (
  { caseId, relation, searchQuery, status, workspaceId }: PaginatedCasesParams,
  options?:
    | UseInfiniteQueryOptions<
        IListOfItemsCursorCases<ICaseDTO> | undefined,
        unknown,
        number[],
        IListOfItemsCursorCases<ICaseDTO> | undefined,
        QueryKey
      >
    | undefined,
) => {
  const casesQuery = useInfiniteQuery<IListOfItemsCursorCases<ICase> | undefined, unknown, number[]>(
    [QUERY_TYPE.CASE, { relation, searchQuery, status, workspaceId }],
    async (data) => {
      if (!data) return
      let newCursor = undefined
      if (caseId && workspaceId && !data.pageParam?.cursor && !searchQuery) {
        const { nextPageCursor } = await caseService.getNextPrevCasesCursor(caseId, workspaceId, relation)
        newCursor = nextPageCursor
      }
      const result = await caseService.fetchCases(
        {
          cursor: data.pageParam?.cursor || newCursor,
          query: searchQuery,
          rel: relation,
          status,
        },
        workspaceId,
      )
      if (!result) return
      return result
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage?.last
          ? false
          : {
              cursor: lastPage?.nextPageCursor,
            },

      getPreviousPageParam: (firstPage) =>
        firstPage?.first
          ? false
          : {
              cursor: firstPage?.previousPageCursor,
            },

      keepPreviousData: false,
      select: (data): InfiniteData<number[]> => ({
        pageParams: data.pages?.map((list) => {
          if (list) {
            const { content, ...params } = list
            return params
          }
        }),
        pages: data.pages.map((list) => list?.content.map((item) => item.caseId) ?? []),
      }),
      ...options,
    },
  )
  const pages = casesQuery.data?.pages || []
  const ids: number[] = pages.flatMap((page) => page)
  //@ts-ignore
  const total = casesQuery.data?.pageParams[0] ? casesQuery.data?.pageParams[0].totalElements : null
  return {
    ...casesQuery,
    ids,
    relation,
    total,
  }
}

export const useCaseCache = (caseId: number) => {
  const queryClient = useQueryClient()
  return queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
}

export const useCaseQuery = (
  { caseId, source }: { caseId?: number; source: ISource },
  queryConfig?: UseQueryOptions<ICase | undefined, any>,
) =>
  useQuery<ICase | undefined>(
    [QUERY_TYPE.CASE, caseId],
    async () => {
      if (caseId) {
        const res = await caseService.fetchCase(caseId, source)
        if (!res) return
        return res
      }
    },
    {
      enabled: !!caseId,
      staleTime: Infinity,
      ...queryConfig,
    },
  )

export const useCaseDocumentsQuery = (
  { caseId, source }: { caseId?: number; source: ISource },
  queryConfig?: UseQueryOptions<IFile[] | undefined, any>,
) =>
  useQuery<IFile[] | undefined>(
    [QUERY_TYPE.CASE_DOCS, caseId],
    async () => {
      if (!caseId) return
      if (source === 'ATLAS') return
      const res = await caseService.fetchCaseDocs(caseId, source)
      const parseCaseRecordDocs: IFile[] =
        res?.map((item: ICaseDocument) => ({
          lastModified: new Date(item.createdAt).getTime(),
          lastModifiedDate: item.createdAt,
          name: item.fileName,
          size: item.fileSize,
          type: item.mimeType,
          uid: item.documentId,
        })) || []
      return parseCaseRecordDocs
    },
    {
      cacheTime: 0,
      enabled: !!caseId,
      staleTime: Infinity,
      ...queryConfig,
    },
  )

export const useChangeCaseStatusMutation = (caseId: number, config?: UseMutationOptions<ICase>) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  return useMutation(
    async (status: ICase['status']) => {
      const result = await post({
        config: {
          params: {
            caseStatus: status,
          },
        },
        url: `/case/${caseId}/status`,
      })
      notices.info({
        message:
          status === 'ARCHIVE'
            ? `${t('Случай')} "${caseRecord?.name}" ${t('помещен в архив')}`
            : `${t('Случай')} "${caseRecord?.name}" ${t('возвращен из архива')}`,
      })
      queryClient.setQueryData<ICase | undefined>(
        [QUERY_TYPE.CASE, caseId],
        (record) => record && { ...record, status },
      )
      return result
    },
    {
      onSuccess: () => {
        if (caseRecord) {
          const status = caseRecord.status?.toLocaleLowerCase()
          const relation = caseRecord.relation?.toLocaleLowerCase()
          queryClient.invalidateQueries([QUERY_TYPE.CASE, { relation, status }])
        }
      },
    },
  )
}
export const useDeleteCaseMutation = (caseId: number, refetchCases: () => void) => {
  const queryClient = useQueryClient()
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const notificationKey = 'delete-' + caseId
  const onRestore = async (caseId: number) => {
    await post({
      url: `/case/${caseId}/restore`,
    })
    notices.close(notificationKey)
    if (caseRecord) {
      refetchCases()
    }
  }
  return useMutation(
    async () => {
      const result = await del({
        url: `/case/${caseId}`,
      })
      queryClient.invalidateQueries([QUERY_TYPE.CASE, caseId])
      notices.openOnCaseRemoveNotification({
        id: caseId,
        key: notificationKey,
        name: caseRecord?.name || caseId.toString(),
        onRestore,
      })
      return result
    },
    {
      onSuccess: () => {
        if (caseRecord) {
          const status = caseRecord.status?.toLocaleLowerCase()
          const relation = caseRecord.relation?.toLocaleLowerCase()
          queryClient.invalidateQueries([QUERY_TYPE.CASE, { relation, status }])
        }
      },
    },
  )
}

export const useMutateCase = (caseId: number) => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: { data: CaseFormDataType; newFiles: IFile[]; deletedFiles?: IFile[] }) => {
      const result = await caseService.updateCase(
        {
          ...data.data,
          materialTakeoutDate: data.data.materialTakeoutDate as string,
          status: 'OPEN',
        },
        caseId,
      )
      if (result) {
        await Promise.all(data.newFiles.map((item) => caseService.uploadDocument(item?.originFileObj, caseId)))
        data.deletedFiles &&
          (await Promise.all(data.deletedFiles.map((item) => caseService.deleteDocument(item.uid, caseId))))
      }

      queryClient.setQueryData([QUERY_TYPE.CASE, caseId], result)
    },

    {
      onError: () => queryClient.invalidateQueries([QUERY_TYPE.CASE_DOCS, caseId]),
      onSuccess: () => queryClient.invalidateQueries([QUERY_TYPE.CASE_DOCS, caseId]),
    },
  )
}

export const useCasesRecordsQuery = (payload: {
  relation: 'owner' | 'shared'
  status: 'open' | 'archive'
  searchQuery?: string
}) => {
  const queryClient = useQueryClient()
  const { ids } = useCasesQuery(payload)

  return ids
    .map((id) => queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, id]))
    .filter((item) => item !== undefined) as ICase[]
}

export const useCaseBioMaterialsQuery = (
  { caseId, workspaceId }: { caseId: number; workspaceId?: number },
  queryConfig?: UseQueryOptions<CaseBiomaterial[]>,
) => {
  const isTaskViewer = useSelector(selectTasksViewerUrlTaskId)
  const isAtlasViewer = useSelector(selectAtlasViewerUrlSlideId)

  return useQuery<CaseBiomaterial[]>(
    [QUERY_TYPE.CASE_BIO_MATERIALS, caseId],
    async () => {
      if (caseId && workspaceId && !isTaskViewer && !isAtlasViewer) {
        return await caseService.getCaseBioMaterials(caseId, workspaceId)
      }
      return []
    },
    {
      cacheTime: 0,
      enabled: !!caseId && !!workspaceId,
      staleTime: Infinity,
      ...queryConfig,
    },
  )
}

export const useCaseReferencesQuery = (
  { caseId, workspaceId }: { caseId: number; workspaceId?: number },
  queryConfig?: UseQueryOptions<CaseReference[]>,
) => {
  const queryClient = useQueryClient()
  const token = localStorage.getItem('token')

  return useQuery<CaseReference[]>(
    [QUERY_TYPE.CASE_REFERENCE_SLIDES, caseId],
    async () => {
      if (caseId && workspaceId && token) {
        const res = caseService.getCaseReference(caseId, workspaceId)
        res
          .then((item) => item.map(({ slideExternalId, stain }) => convertToISlide({ caseId, slideExternalId, stain })))
          .then((item) => {
            if (!item && !caseId) return
            item.map((slide: ISlide) => {
              queryClient.setQueryData<ISlide>([QUERY_TYPE.SLIDE, slide.slideId], () => ({
                ...slide,
                caseId,
              }))
            })
          })
        return res
      }
      return []
    },
    {
      cacheTime: 0,
      enabled: !!caseId && !!workspaceId,
      staleTime: Infinity,
      ...queryConfig,
    },
  )
}

export const useCaseSlideGrouped = (caseId: number, queryConfig?: UseQueryOptions<ISlideGrouped[]>) => {
  const queryClient = useQueryClient()
  return useQuery<ISlideGrouped[]>(
    [QUERY_TYPE.SLIDE_GROUPED, caseId],
    async () => {
      if (!caseId) return []

      const res = await caseService.fetchSlideGrouped(caseId)
      const flatSlides = res.content?.flatMap((item) => item.slides.map((slide) => slide)) || []
      for (const slide of flatSlides) {
        queryClient.setQueryData<ISlide>([QUERY_TYPE.SLIDE, slide.slideId], () => ({
          ...slide,
          caseId,
        }))
      }

      return res.content
    },
    {
      enabled: !!caseId,
      staleTime: Infinity,
      ...queryConfig,
    },
  )
}
